@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap");
.ant-modal-content{
    padding: 0px !important;
}
.site-form-item-icon{
    opacity: 0.5;
}

.dot-menu-icons{
    padding:4px;
    border-radius:5px;
    cursor:pointer;
    width: 80px;
  }
  
  .dot-menu-icons img{
    height:20px;
    width:20px;
  }
  
  .dot-menu-icons:hover{
    background: #F5F6F8;
  }

  .home-menu-icons{
    padding:4px;
    border-radius:5px;
    cursor:pointer;
    width: 200px;
  }
  
  .home-menu-icons img{
    height:80px;
    width:80px;
  }
  
  .home-menu-icons:hover{
    background: #F5F6F8;
  }
  